import moment from 'moment'
import ClipboardJS from 'clipboard'

# hide_or_show_verfahren = () ->  
#   eval_verfahren = $("#eval_verfahrensart").val()
#   if eval_verfahren == 'klein'
#     $("#small_procedure").show();
#     $("#big_procedure").hide();
#   else if eval_verfahren == 'groß'
#     $("#small_procedure").hide();
#     $("#big_procedure").show();
#   else
#     $("#big_procedure").hide();
#     $("#small_procedure").hide();

## Method for required stgs in cluster/new constraints
$(document).on 'click', '.new_constraints', ->
  requiredCheckboxes = $('.new_constraints :checkbox[required]')
  console.log(requiredCheckboxes)
  requiredCheckboxes.change ->
    console.log("check")
    if requiredCheckboxes.is(':checked')
      requiredCheckboxes.removeAttr 'required'
    else
      requiredCheckboxes.attr 'required', 'required'
    return
  return

$(document).on 'turbolinks:load', ->

  # # Turbolinks page refresh every 30 seconds
  # if !window.reloadTimer
  #   SECONDS = 30
  #   window.reloadTimer = setTimeout((->
  # #     console.log 'Reload!'
  #     window.reloadTimer = undefined
  #     Turbolinks.visit location.toString(), action: 'replace'
  #     return
  #   ), SECONDS * 1000)
  #   document.addEventListener 'turbolinks:visit', ->
  #     clearTimeout window.reloadTimer
  #     window.reloadTimer = undefined
  #     return
 

  ## Autocomplete for cooperation filter
  $input = $('*[data-behavior="autocomplete"]')
  options = 
    url: (phrase) ->
      '/coops.json'
    getValue: 'name'
  $input.easyAutocomplete options

  clipboard = new ClipboardJS('.clipboard-btn');

  $ ->
    $('[data-toggle="popover"]').popover()
    return

  $('#check_constraints').on 'click', ->
    alert("Die Änderung wird erst nach dem Speichern des Datensatzes aktiv")
    $('*[id*=co_re]').each ->
      this.checked = true
      return

  # $("#eval_verfahrensart").on 'change', ->
  #   hide_or_show_verfahren()

  ## Fristenberechnung in den Evaluationsdaten
  buttons = document.getElementsByClassName('eval_frist')
  i = 0
  while i < buttons.length
    buttons[i].addEventListener 'click', ->
      result = confirm('Alle Fristen überschreiben? Die Änderung wird nach der Speicherung des Evaluationsdatensatzes aktiv.')
      if result
        eval_id = this.getAttribute('value')
        cl_date = $('#eval_date_'+eval_id).data('eval_date')
        $('#beginn_eval_'+eval_id).val(moment(cl_date).subtract(49, 'months').format("YYYY-MM-DD"))
        # $('#austausch_steva_'+eval_id).val(moment(cl_date).subtract(55, 'months').format("YYYY-MM-DD"))
        # $('#erstkontakt_fb_'+eval_id).val(moment(cl_date).subtract(51, 'months').format("YYYY-MM-DD"))
        # $('#gruppendiskussion_'+eval_id).val(moment(cl_date).subtract(49, 'months').format("YYYY-MM-DD"))
        # $('#expertengespraech_'+eval_id).val(moment(cl_date).subtract(48, 'months').format("YYYY-MM-DD"))
        # $('#abschlussdiskussion_'+eval_id).val(moment(cl_date).subtract(47, 'months').format("YYYY-MM-DD"))
        # $('#studiengangsentwicklungsgespraech_'+eval_id).val(moment(cl_date).subtract(46, 'months').format("YYYY-MM-DD"))
        return
    i++


  get_deadline_by_fix_id = (fix_id, type, cl_date, field) ->
    $.ajax
      url: "/deadline_calculations/"+fix_id+".json"
      dataType: 'json'
      success: (data) ->
        calc_date = moment(cl_date).subtract(data[type], 'month')
        $(field).val(calc_date.format("YYYY-MM-DD"))

  # # Fristenberechnung in den Clusterdaten
  $('#fristen_berechnen_a').on 'click', ->
    cl_date = $('#cluster_akkreditierungsfrist').val()
    month_number = moment(cl_date).month()
    sose = true
    year_add = 0
    if month_number <= 2 || month_number >= 9
      sose = false
    if month_number >= 9
      year_add = 1

    result = confirm('Alle Fristen nach Methode A überschreiben? Die Änderung wird nach der Speicherung des Clusters aktiv.')
    if result
      if sose
        set_deadline_fields("so_a_num", cl_date)
      else
        set_deadline_fields("wi_a_num", cl_date)

  $('#fristen_berechnen_b').on 'click', ->
    cl_date = $('#cluster_akkreditierungsfrist').val()
    month_number = moment(cl_date).month()
    sose = true
    year_add = 0
    if month_number <= 2 || month_number >= 9
      sose = false
    if month_number >= 9
      year_add = 1

    result = confirm('Alle Fristen nach Methode B überschreiben? Die Änderung wird nach der Speicherung des Clusters aktiv.')
    if result
      if sose
        set_deadline_fields("so_b_num", cl_date)
      else
        set_deadline_fields("wi_b_num", cl_date)

  set_deadline_fields = (type, cl_date) -> 
    get_deadline_by_fix_id(1, type, cl_date, '#cluster_startschusskommunikation')
    get_deadline_by_fix_id(2, type, cl_date, '#cluster_kick_off')
    get_deadline_by_fix_id(3, type, cl_date, '#cluster_gutachterbenennung')
    get_deadline_by_fix_id(4, type, cl_date, '#cluster_abgabe_ordnung')
    get_deadline_by_fix_id(5, type, cl_date, '#cluster_runder_tisch')
    get_deadline_by_fix_id(6, type, cl_date, '#cluster_abgabe_antrag')
    get_deadline_by_fix_id(7, type, cl_date, '#cluster_begehung')
    get_deadline_by_fix_id(8, type, cl_date, '#cluster_entscheidung')
    get_deadline_by_fix_id(9, type, cl_date, '#cluster_studienstart_ordnung')


  # Fristenberechnung in den Clusterdaten
  # $('#fristen_berechnen').on 'click', ->
  #   cl_date = $('#cluster_akkreditierungsfrist').val()
  #   month_number = moment(cl_date).month()
  #   sose = true
  #   year_add = 0
  #   if month_number <= 2 || month_number >= 9
  #     sose = false
  #   if month_number >= 9
  #     year_add = 1

  #   result = confirm('Alle Fristen überschreiben? Die Änderung wird nach der Speicherung des Clusters aktiv.')
  #   if result
  #     if sose
  #       # akkreditierungsfrist = moment((moment(cl_date).year()+year_add)+"-09-30")
  #       entscheidung = moment(cl_date).subtract(5, 'months')
  #       startschuss = moment(cl_date).subtract(27, 'months')
  #       kick_off = moment(cl_date).subtract(22, 'months')
  #       ordnung = moment(cl_date).subtract(14, 'months')
  #       runder_tisch = moment(cl_date).subtract(12, 'months')
  #       gutachterbenennung = moment(cl_date).subtract(21, 'months')
  #       abgabe_antrag = moment(cl_date).subtract(10, 'months')
  #       begehung = moment(cl_date).subtract(8, 'months')
  #     else
  #       # akkreditierungsfrist = moment((moment(cl_date).year()+year_add)+"-03-31")
  #       entscheidung = moment(cl_date).subtract(5, 'months')
  #       startschuss = moment(cl_date).subtract(26, 'months')
  #       kick_off = moment(cl_date).subtract(22, 'months')
  #       ordnung = moment(cl_date).subtract(14, 'months')
  #       runder_tisch = moment(cl_date).subtract(13, 'months')
  #       gutachterbenennung = moment(cl_date).subtract(21, 'months')
  #       abgabe_antrag = moment(cl_date).subtract(10, 'months')
  #       begehung = moment(cl_date).subtract(7, 'months')

  #     # console.log(akkreditierungsfrist)
  #     # $('#cluster_akkreditierungsfrist').val(akkreditierungsfrist.format("YYYY-MM-DD"))
  #     $('#cluster_entscheidung').val(entscheidung.format("YYYY-MM-DD"))
  #     $('#cluster_startschusskommunikation').val(startschuss.format("YYYY-MM-DD"))
  #     $('#cluster_kick_off').val(kick_off.format("YYYY-MM-DD"))
  #     $('#cluster_abgabe_ordnung').val(ordnung.format("YYYY-MM-DD"))
  #     $('#cluster_runder_tisch').val(runder_tisch.format("YYYY-MM-DD"))
  #     $('#cluster_gutachterbenennung').val(gutachterbenennung.format("YYYY-MM-DD"))
  #     $('#cluster_abgabe_antrag').val(abgabe_antrag.format("YYYY-MM-DD"))
  #     $('#cluster_begehung').val(begehung.format("YYYY-MM-DD"))

  # hide_or_show_verfahren()




  

